.map-spaces-container{
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.space-map-section {
    width: 80vw;
    height: 70vh;
    margin: auto;
    float: left;
    position: fixed;
    left: 0;
}
.space-map-side-section{
    width: 20vw;
    height: 100vh;
    margin: auto;
    background:white;
    right:0;
    position: fixed;
}
.leaflet-container {
    position: fixed !important;
    overflow: unset !important;
    height: 100%;
    width: 100%;
}

.leaflet-control {
    margin-top: 80px !important;
    margin-right: 20px !important;
    box-shadow: none!important;
}

.leaflet-control-attribution {
    margin-bottom: 10px !important;
    margin-left: 99px !important;
    width: 100% !important;
}


.leaflet-marker-icon.marker-cluster.marker-cluster-small.leaflet-zoom-animated.leaflet-interactive{
    width: 35px;
    height: 35px;
}
.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive{
    width: 35px;
    filter: drop-shadow(1px 1px 0px #1a1a1a);
}
.marker-cluster div{
    font-weight: 700;
    width: 40px;
    height: 40px;
    margin-left: 0;
    margin-top: 0;
    font-size: 14px;
    border-radius: 50px;
}
.marker-cluster span {
    line-height: 40px;
}
.marker-cluster-small {
    background-color: rgba(0, 0, 0, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(175, 255, 131, 0.6);
}

.leaflet-popup-content .place-block-popup{
    border-radius: 0;
}

.leaflet-popup-content-wrapper{
    border-radius: 0;
}
.leaflet-container a.leaflet-popup-close-button {
    font: 20px/24px Tahoma, Verdana, sans-serif;
    color: white;
    text-shadow: 1px 1px 1px gray;
}
.leaflet-popup-content {
    margin: 0;
    line-height: 1.3;
    font-size: 13px;
    font-size: 1.08333em;
    min-height: 1px;
}
.block-place-address-title,.block-place-description-title {
    font-weight: 700;
}
.leaflet-popup-content .block-place-address, .leaflet-popup-content .block-place-description {
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0 !important;
    max-height: 300px;
    overflow-y: auto;
}

.leaflet-popup-content .block-place-name{
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
}
.space-filters-section {
    float: left;
    width: 100%;
}
/*.space-filters-section[data-showfilters="false"] {*/
/*    width: 100px;*/
/*}*/
.category_not_available{
    display:none;
}
.space-filters-title {
    text-align: center;
    color: white;
    font-size: 15px;
    font-weight: 700;
    background: black;
    padding: 5px;
    width: 100%;
    text-transform: none;
    margin-bottom: 0 !important;
    cursor: pointer;
}
.space-filters-title:hover {
    background: black;
    font-size: 16px;
}
.space-filter-category-title {
    width: 100%;
    clear: both;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.space-filter-category-image img {
    width: 100%;
    padding: 5px 10px;
}
.space-category-filter-option {
    width: 25%;
    float: left;
    cursor: pointer;
    padding: 0 5px;
    margin: 10px 0;
}
.space-filter-category-image.selected-icon {
    filter: drop-shadow(-1px -1px 0px #000) drop-shadow(1px -1px 0px #000) drop-shadow(1px 1px 0px #000) drop-shadow(-1px 1px 0px #000);
}



.map-go-back-button-to-select{
    z-index:10000;
    position: absolute;
    left:0;
    top:0;
}


@media only screen and (max-width: 1200px) {

    .space-filter-category-title {
        white-space: nowrap;
        font-size: 10px;
    }
    .space-filters-section {
        padding-left: 0;
        width: 100vw;
    }
    .space-category-filter-option{
        margin: 0;
        width: 33.33%;
    }
    .space-filter-category-image img {
        padding: 0 15px;
    }
    .space-map-section {
        width: 100vw;
        height: 70vh;
        margin: auto;
        float: left;
        position: fixed;
        left: 0;
    }
    .space-map-side-section{
        width: 100vw;
        height: auto;
    }

}

@media only screen and (max-width: 700px) {
    .space-form-container.add-form .space-form{
        width: 100% !important;
    }
    .space-category-filter-option {
        padding: 0;
        width: 33.33%;
    }
    .space-filter-category-title {
        font-size: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
    }
    .space-filters-section {
        right:0;
        width: 100vw;
    }
    .space-form-container .form-add-button-block {
        bottom: 30px;
        right: 10px;
    }
}


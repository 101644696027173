.language-selector {
    position: relative;
    width: 100px;
    cursor: pointer;
    right: 0;
    margin-left: 50px;
}
.language-selector .dropdown-container svg {
    max-width: 26px;
    max-height: 26px;
    margin-right: 7px;
    border-radius:5px;
}
.language-selector .options-container .option {
    margin-bottom: 1px;
}
.language-selector .options-container {
    position: absolute;
    border: 1px solid #d9d9d9;
    background: white;
    padding: 5px;
    width: 120px;

}
.options-container .option:hover {
    background: #b5b5b5;
}

.dropdown-option-icon {
    float: left;
}
.dropdown-option-label {
    float: left;
    padding-left: 6px;
}
.language-selector .selected-option {
    float: right;
    position: relative;
    height: 44px;
    line-height: 30px;
    width: 110px;
    padding: 5px;
    left: 10px;
}



@media only screen and (max-width: 768px) {
    .navbar-collapse +  .language-selector {
        display: none;
    }

    .navbar-collapse.collapse.show + .language-selector {
        display: block;
        text-align: center;
        left:0;
        right:0;
        margin:auto;
        margin-top:10px;
    }

}
